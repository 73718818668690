import React from 'react';
import { Container, ButtonNfe } from './styles';
import { MdSearch } from 'react-icons/md';
import { useBuscaNfe } from './BuscaNfeContext';
import { ModalBuscaNfe } from './ModalBuscaNfe';
import { toast } from 'react-toastify';

export const BuscaNfeContent: React.FC = () => {
  const { setIsOpenModal, quantityItems, buscaDisabled } = useBuscaNfe();
  return (
    <Container>
      <ButtonNfe
        className="success"
        type="button"
        onClick={() => {
          if (quantityItems > 0) {
            return toast.warning('Exclua os produtos já incluídos na NF.');
          }
          setIsOpenModal(true);
        }}
        disabled={buscaDisabled}
      >
        <MdSearch size={25} /> BUSCAR XML VALIDADO
      </ButtonNfe>
      <ModalBuscaNfe />
    </Container>
  );
};
